import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../utils/AuthUtils";

const EditSliderPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    image_url: null,
    redirected_url: "",
    start_date: "",
    end_date: "",
    is_active: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [formErrors, setFormErrors] = useState({}); // Track validation errors
  const navigate = useNavigate();

  // Fetch slider details when the component mounts
  useEffect(() => {
    const fetchSlider = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/slider/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const sliderData = response.data.data;
          setFormData({
            title: sliderData.title,
            image_url: sliderData.image_url,
            redirected_url: sliderData.redirected_url,
            start_date: sliderData.start_date.split("T")[0],
            end_date: sliderData.end_date.split("T")[0],
            is_active: sliderData.is_active,
          });
          setImagePreview(sliderData.image_url);
        }
      } catch (err) {
        setError("Error fetching slider details");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSlider();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        image_url: files[0],
      }));
      setImagePreview(URL.createObjectURL(files[0]));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({
      ...formData,
      image_url: formData.image_url, // Retain the existing image if user removes the preview
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate form fields
    let errors = {};
    if (!formData.title) errors.title = "Title is required";
    if (!formData.image_url && !formData.image_url instanceof File) errors.image_url = "Image is required";
    if (!formData.redirected_url) errors.redirected_url = "Redirected URL is required";
    if (!formData.start_date) errors.start_date = "Start date is required";
    if (!formData.end_date) errors.end_date = "End date is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if any field is missing
      setLoading(false);
      return; // Prevent form submission if there are errors
    }

    const data = new FormData();
    for (const key in formData) {
      if (key === "image_url" && formData.image_url instanceof File) {
        data.append(key, formData.image_url);
      } else if (key === "image_url") {
        data.append(key, formData.image_url); // Append the existing image URL
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/slider/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        navigate("/slider_list", { state: { snackbarMessage: "Slider updated successfully" } });
      }
    } catch (err) {
      setError("Error updating slider");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/slider_list"); // Redirect to the slider list page
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Slider
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
            required
            error={!!formErrors.title} // Highlight if there's an error
            helperText={formErrors.title} // Show error message
          />
          <input
            name="image_url"
            type="file"
            accept="image/*"
            onChange={handleChange}
            required
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "200px", height: "auto", borderRadius: "4px" }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <TextField
            name="redirected_url"
            label="Redirected URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.redirected_url}
            onChange={handleChange}
            required
            error={!!formErrors.redirected_url} // Highlight if there's an error
            helperText={formErrors.redirected_url} // Show error message
          />
          <TextField
            name="start_date"
            label="Start Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.start_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
            error={!!formErrors.start_date} // Highlight if there's an error
            helperText={formErrors.start_date} // Show error message
          />
          <TextField
            name="end_date"
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.end_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
            error={!!formErrors.end_date} // Highlight if there's an error
            helperText={formErrors.end_date} // Show error message
          />
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#e0e0e0", mr: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Update Slider"}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditSliderPage;
