import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Button,
  Typography,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles
import axios from "axios";
import { textAlign } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../utils/AuthUtils";

const toolbarOptions = [
  [{ font: [] }],
  [{ size: [] }],
  ["bold", "italic", "underline", "strike"],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  ["link"],
  ["clean"],
];

const saveContent = async (
  contentSections,
  setErrorVal,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
  navigate
) => {
  const formData = new FormData();

  formData.append(`position`, localStorage.getItem("position"));
  formData.append(`chapter`, localStorage.getItem("chapter"));
  formData.append(`grade`, localStorage.getItem("grade"));
  formData.append(`course`, localStorage.getItem("course"));
  formData.append(`unitHeading`, localStorage.getItem("unitHeading"));
  // Append the heading to formData
  // if (contentSections[0]?.unitHeading) {
  //   formData.append(`unitHeading`, contentSections[0].unitHeading);
  // }

  contentSections.forEach((section, index) => {
    formData.append(`contentSections[${index}][type]`, section.type);
    formData.append(`contentSections[${index}][data]`, section.data || "");

    if (section.galleryFiles) {
      formData.append(
        `contentSections[${index}][count]`,
        section.galleryFiles.length
      );
      for (let i = 0; i < section.galleryFiles.length; i++) {
        formData.append(
          `contentSections[${index}][galleryFiles][]`,
          section.galleryFiles[i]
        );
      }
    }

    if (section.pdfFiles) {
      for (let i = 0; i < section.pdfFiles.length; i++) {
        formData.append(
          `contentSections[${index}][pdfFiles][]`,
          section.pdfFiles[i]
        );
      }
    }

    if (section.videoFile) {
      formData.append(
        `contentSections[${index}][videoFile]`,
        section.videoFile
      );
    }

    if (section.videoUrl) {
      formData.append(`contentSections[${index}][videoUrl]`, section.videoUrl);
    }

    if (section.youtubeUrl) {
      formData.append(
        `contentSections[${index}][youtubeUrl]`,
        section.youtubeUrl
      );
    }

    if (section.vimeoUrl) {
      formData.append(`contentSections[${index}][vimeoUrl]`, section.vimeoUrl);
    }

    if (section.imageFile) {
      formData.append(
        `contentSections[${index}][imageFile]`,
        section.imageFile
      );
    }
  });
  for (let [key, value] of formData.entries()) {
    console.log(`${key}:`, value);
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/unitupload/save`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Success:", formData);
    console.log("Success response:", response);
    localStorage.removeItem("unitHeading");
    localStorage.removeItem("course");
    localStorage.removeItem("grade");
    localStorage.removeItem("chapter");
    localStorage.removeItem("position");
    navigate("/unitlist"); // Navigate after successful save
  } catch (err) {
    if (err.response && err.response.data && err.response.data.errors) {
      const errors = err.response.data.errors;

      // Handle errors field by field and set them in the error state
      const formErrors = {};
      errors.forEach((error) => {
        formErrors[error.field] = error.message;
      });

      // Update the error state with the field-specific error messages
      setErrorVal(formErrors);

      // Trigger error snackbar with a generic error message
      setSnackbarMessage(
        "Failed to add Partner. Please check your inputs and try again."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      // If no specific validation errors, show a generic error message
      setSnackbarMessage(
        "An unexpected error occurred. Please try again later."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    console.error("Error:", err);
  }
};

// const TextEditor = (value, onChange) => {
//   useEffect(() => {
//     const tableModule = new Table();
//     tableModule.attach();
//   }, []);
// };

const TextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      modules={{
        toolbar: toolbarOptions,
        table: true, // Enable table module
      }}
    />
  );
};

const DynamicContentForm = () => {
  const [contentSections, setContentSections] = useState([]);
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [courseList, setCourseList] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [CourseData, setCourseData] = useState([]);
  const [GradeData, setGradeData] = useState([]);
  const [ChapterData, setChapterData] = useState([]);
  const [unitHeading, setUnitHeading] = useState("");
  const [index, setIndex] = useState("");
  const [lastPosition, seLastPosition] = useState("");


  const [errorVal, setErrorVal] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success or error

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("unitHeading");
    localStorage.removeItem("course");
    localStorage.removeItem("grade");
    localStorage.removeItem("chapter");
    localStorage.removeItem("position");
  }, []);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/grade`
        );
        setGrades(response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };

    fetchGrades();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const token = getToken();
        if (token && GradeData) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/courses/bygrade/${GradeData}`,
            {
              headers: { Authorization: `Bearer ${token}` },
              // params: { gradeId: GradeData },
            }
          );
          setCourseList(response.data.data);
        } else {
          setCourseList([]);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [GradeData]);

  useEffect(() => {
    const fetchChapters = async () => {
      const token = getToken();

      try {
        if (CourseData) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/chapters/bycourse/${CourseData}`,
            {
              headers: { Authorization: `Bearer ${token}` },
              // params: { courseId: CourseData },
            }
          );
          setChapters(response.data.data || []);
        } else {
          setChapters([]);
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchChapters();
  }, [CourseData]);

  useEffect(() => {
    const fetchLatestPosition = async () => {
      const token = getToken();

      try {
        if (CourseData) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/unitupload/latestposition/${CourseData}/${ChapterData}`,
            {
              headers: { Authorization: `Bearer ${token}` },
              // params: { courseId: CourseData },
            }
          );
          seLastPosition(response.data.data);
          console.log(response.data.data);
          localStorage.setItem("position", (response.data.data.largestPosition + 1 || 1));
        } else {
          seLastPosition("");
        }
      } catch (error) {
        console.error("Error fetching chapters:", error);
      }
    };

    fetchLatestPosition();
  }, [CourseData, ChapterData]);

  const handleSave = async () => {
    try {
      await saveContent(
        contentSections,
        setErrorVal,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen,
        navigate
      ); // Wait for content to save
    } catch (error) {
      console.error("Failed to save content", error);
    }
  };
  const handleContentTypeChange = (event) => {
    const { value } = event.target;
    setContentSections((prevSections) => [
      ...prevSections,
      { type: value, id: new Date().getTime(), data: "" },
    ]);
  };
  const handleRemoveSection = (index) => {
    setContentSections((prevSections) =>
      prevSections.filter((_, i) => i !== index)
    );
  };

  const handleDataChange = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChange = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeHeading1 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeHeading2 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeSubHeading1 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };
  const handleParagraphChangeSubHeading2 = (index, value) => {
    const updatedSections = [...contentSections];
    updatedSections[index].data = value;
    setContentSections(updatedSections);
  };

  const handleGalleryChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].galleryFiles = event.target.files;
    updatedSections[index].count = event.target.files.length;
    setContentSections(updatedSections);
  };

  const handlePdfUploadChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].pdfFiles = event.target.files;
    setContentSections(updatedSections);
  };

  const handleVideoUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].videoUrl = value;
    setContentSections(updatedSections);
  };

  const handleYoutubeUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].youtubeUrl = value;
    setContentSections(updatedSections);
  };

  const handleVimeoUrlChange = (index, event) => {
    const { value } = event.target;
    const updatedSections = [...contentSections];
    updatedSections[index].vimeoUrl = value;
    setContentSections(updatedSections);
  };

  const handleVideoUploadChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].videoFile = event.target.files[0];
    setContentSections(updatedSections);
  };

  const handleSingleImageChange = (index, event) => {
    const updatedSections = [...contentSections];
    updatedSections[index].imageFile = event.target.files[0];
    updatedSections[index].count = 1;
    setContentSections(updatedSections);
  };

  // const handleHeadingChange = (event) => {
  //   console.log(event.target);

  //   const updatedSections = [...contentSections];
  //   updatedSections[0].unitHeading = event.target.value;
  //   setContentSections(updatedSections);
  // };

  const handleHeadingChange = (event) => {
    setUnitHeading(event.target.value);
    const updatedSections = [...contentSections];

    // Make sure there is a section for the heading, if not, create it
    if (!updatedSections[0]) {
      updatedSections[0] = { unitHeading: "", type: "unitHeading" };
    }
    const untHeadings = event.target.value;
    updatedSections[0].data = event.target.value;
    localStorage.setItem("unitHeading", untHeadings);
    setContentSections(updatedSections);
  };
  const handleChapterInputChange = (event) => {
    const Chapter = event.target.value;
    localStorage.setItem("chapter", Chapter);
    setChapterData(Chapter);
  };
  const handleIndexInputChange = (event) => {
    const position = event.target.value;
    localStorage.setItem("position", position);
    setIndex(position);
  };

  const handleGradeInputChange = (event) => {
    const Grade = event.target.value;
    localStorage.setItem("grade", Grade);
    setGradeData(Grade);
    setCourseData(""); // Reset course and chapter selections when grade changes
    setChapterData("");
  };

  const handleCourseInputChange = (event) => {
    const Course = event.target.value;
    localStorage.setItem("course", Course);
    setCourseData(Course);
    setChapterData(""); // Reset chapter selection when course changes
  };

  const handleSelectFocus = () => {
    if (!unitHeading) {
      setSnackbarMessage("Please add Unit Heading first.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        padding: 3,
        marginLeft: "0px",
      }}
    >
      <Typography variant="h5">Unit Uploads</Typography>
      {/* <Divider sx={{ marginBottom: 3 }} /> */}

      {/* <TextField
        type="Number"
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder="Position"
        label="Position"
        sx={{ marginBottom: 3 }}
        value={lastPosition.largestPosition}
        onChange={handleIndexInputChange}
      />
      {errorVal.Index && (
        <span className="error-message">{errorVal.Index}</span>
      )} */}

      <TextField
        label="Grade"
        variant="outlined"
        fullWidth
        margin="normal"
        name="grade"
        select
        value={GradeData}
        onChange={handleGradeInputChange}
        required
      >
        {grades.length > 0 ? (
          grades.map((grade) => (
            <MenuItem key={grade._id} value={grade._id}>
              {grade.gradename}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Grades
          </MenuItem>
        )}
      </TextField>
      {errorVal.Partner_Category_id && (
        <span className="error-message">{errorVal.Partner_Category_id}</span>
      )}

      <TextField
        label="Course"
        variant="outlined"
        fullWidth
        margin="normal"
        name="course"
        select
        value={CourseData}
        onChange={handleCourseInputChange}
        required
      >
        {courseList.length > 0 ? (
          courseList.map((course) => (
            <MenuItem key={course._id} value={course._id}>
              {course.courses_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Courses
          </MenuItem>
        )}
      </TextField>
      {errorVal.Partner_Category_id && (
        <span className="error-message">{errorVal.Partner_Category_id}</span>
      )}

      <TextField
        label="Chapter"
        variant="outlined"
        fullWidth
        margin="normal"
        name="chapter"
        select
        value={ChapterData}
        onChange={handleChapterInputChange}
        required
      >
        {chapters.length > 0 ? (
          chapters.map((chapter) => (
            <MenuItem key={chapter._id} value={chapter._id}>
              {chapter.chapter_name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No Chapters
          </MenuItem>
        )}
      </TextField>
      {errorVal.Partner_Category_id && (
        <span className="error-message">{errorVal.Partner_Category_id}</span>
      )}

      <TextField
        type="text"
        variant="outlined"
        fullWidth
        margin="normal"
        placeholder="Unit Heading"
        label="Unit Heading"
        sx={{ marginBottom: 3 }}
        value={unitHeading}
        onChange={handleHeadingChange}
      />
      {errorVal.Partner_Category_id && (
        <span className="error-message">{errorVal.Partner_Category_id}</span>
      )}

      {/* Dynamically render fields based on selected content types */}
      {contentSections.map((section, index) => {
        index = index++;
        switch (section.type) {
          case "paragraph":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label>Paragraph</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) => handleParagraphChange(index, value)}
                  modules={{ toolbar: toolbarOptions }}
                />
              </Box>
            );
          case "gallery":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label>Gallery</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ multiple: true, accept: "image/*" }}
                  onChange={(e) => handleGalleryChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "singleimage":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label>Gallery</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => handleGalleryChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "Heading1":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Heading 1</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                {/* <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeHeading1(index, value)
                  }
                /> */}
                <TextField
                  label="Heading"
                  value={section.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                  onChange={(e) =>
                    handleParagraphChangeHeading1(index, e.target.value)
                  } // Handle text change
                  variant="outlined" // TextField variant (outlined, filled, etc.)
                  style={{
                    width: "100%",
                    resize: "none", // Disable resizing
                  }}
                />
              </Box>
            );
          case "Heading2":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Heading 2</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                {/* <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeHeading2(index, value)
                  }
                /> */}
                <TextField
                  label="Heading"
                  value={section.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                  onChange={(e) =>
                    handleParagraphChangeHeading2(index, e.target.value)
                  } // Handle text change
                  variant="outlined" // TextField variant (outlined, filled, etc.)
                  style={{
                    width: "100%",
                    resize: "none", // Disable resizing
                  }}
                />
              </Box>
            );
          case "Subheading1":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Sub Heading 1</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                {/* <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeSubHeading1(index, value)
                  }
                /> */}
                <TextField
                  label="Heading"
                  value={section.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                  onChange={(e) =>
                    handleParagraphChangeSubHeading1(index, e.target.value)
                  } // Handle text change
                  variant="outlined" // TextField variant (outlined, filled, etc.)
                  style={{
                    width: "100%",
                    resize: "none", // Disable resizing
                  }}
                />
              </Box>
            );
          case "Subheading2":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Sub Heading 2</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  x
                </label>
                {/* <ReactQuill
                  theme="snow"
                  value={section.data}
                  onChange={(value) =>
                    handleParagraphChangeSubHeading2(index, value)
                  }
                /> */}
                <TextField
                  label="Heading"
                  value={section.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                  onChange={(e) =>
                    handleParagraphChangeSubHeading2(index, e.target.value)
                  } // Handle text change
                  variant="outlined" // TextField variant (outlined, filled, etc.)
                  style={{
                    width: "100%",
                    resize: "none", // Disable resizing
                  }}
                />
              </Box>
            );
          case "videoUpload":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Video</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "video/*" }}
                  onChange={(e) => handleVideoUploadChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "pdf":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">PDF</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  name="pdfFiles"
                  type="file"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "application/pdf", multiple: true }}
                  onChange={(e) => handlePdfUploadChange(index, e)}
                  label={`Upload PDFs for section ${index + 1}`}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "videoUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Redirection URL Button</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Redirection URL Button"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleVideoUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
                <TextField
                  label="Button"
                  value={section.data.replace(/<\/?p>/g, "")} // Remove <p> tags for editing in TextField
                  onChange={(e) => handleDataChange(index, e.target.value)} // Handle text change
                  variant="outlined" // TextField variant (outlined, filled, etc.)
                  style={{
                    width: "100%",
                    resize: "none", // Disable resizing
                  }}
                />
              </Box>
            );
          case "youtubeUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Youtube URL</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleYoutubeUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "vimeoUrl":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Vimeo URL</label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleVimeoUrlChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          case "singleImage":
            return (
              <Box
                key={section.id}
                sx={{ marginBottom: 3, position: "relative" }}
              >
                <label htmlFor="">Single Image </label>
                <label
                  className="remove-unit-label"
                  onClick={() => handleRemoveSection(index)}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer", // Optional: to show that it's clickable
                  }}
                >
                  ×
                </label>
                <TextField
                  key={section.id}
                  type="file"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => handleSingleImageChange(index, e)}
                  sx={{ marginBottom: 3 }}
                />
              </Box>
            );
          default:
            return null;
        }
      })}

      {/* Main Select to choose content type */}
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel>Choose content</InputLabel>
        <Select
          label="Choose content"
          onChange={handleContentTypeChange}
          sx={{ marginBottom: 3 }}
          disabled={!unitHeading} // Disable Select if Unit Heading is empty
          onClick={handleSelectFocus} // Trigger the Snackbar when the Select is focused
        >
          <MenuItem value="Heading1">Heading 1</MenuItem>
          <MenuItem value="Heading2">Heading 2</MenuItem>
          <MenuItem value="Subheading1">Sub Heading 1</MenuItem>
          <MenuItem value="Subheading2">Sub Heading 2</MenuItem>
          <MenuItem value="paragraph">Paragraph</MenuItem>
          <MenuItem value="pdf">PDF Upload</MenuItem>
          <MenuItem value="gallery">Gallery</MenuItem>
          <MenuItem value="singleimage">Single image</MenuItem>
          {/* <MenuItem value="vimeoUrl">Vimeo URL</MenuItem> */}
          <MenuItem value="youtubeUrl">Youtube URL</MenuItem>
          <MenuItem value="videoUpload">Video Upload</MenuItem>
          <MenuItem value="videoUrl">Redirection URL Button</MenuItem>
        </Select>
        {errorVal.contentSections && (
          <span className="error-message">{errorVal.contentSections}</span>
        )}
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        sx={{ marginTop: 3, width: "20%" }}
      >
        Save
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DynamicContentForm;
