import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Alert,
} from "@mui/material";
import { getToken } from "../utils/AuthUtils";

const AddTown = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [townName, setTownName] = useState("");
  const [townCode, setTownCode] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [status, setStatus] = useState(0);

  // Error states for each field
  const [error, setError] = useState({
    townName: "",
    country: "",
    state: "",
    district: "",
  });

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict("");
    }
  }, [selectedState]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country/nopagination`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch countries:", err.response?.data?.message || err.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch states:", err.response?.data?.message || err.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error("Failed to fetch districts:", err.response?.data?.message || err.message);
    }
  };

  const handleAddTown = async () => {
    // Reset error states before validation
    setError({
      townName: "",
      country: "",
      state: "",
      district: "",
    });

    let isValid = true;

    // Validate each field
    if (!townName.trim()) {
      setError((prev) => ({ ...prev, townName: "Town name is required." }));
      isValid = false;
    }
    if (!selectedCountry) {
      setError((prev) => ({ ...prev, country: "Country is required." }));
      isValid = false;
    }
    if (!selectedState) {
      setError((prev) => ({ ...prev, state: "State is required." }));
      isValid = false;
    }
    if (!selectedDistrict) {
      setError((prev) => ({ ...prev, district: "District is required." }));
      isValid = false;
    }

    // If validation fails, don't proceed with the API call
    if (!isValid) {
      return;
    }

    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/town`,
          {
            Town_name: townName.trim(),
            // Town_code: townCode.trim(),
            country: selectedCountry,
            state: selectedState,
            district: selectedDistrict,
            status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        onAdd(); // Refresh the list
        onClose();
      }
    } catch (err) {
      console.error("Failed to add town:", err.response?.data?.message || err.message);
      setError({ ...error, townName: `Failed to add town: ${err.response?.data?.message || err.message}` });
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true} // Ensures dialog takes up full width
        maxWidth="sm"   // Adjust the maximum width to 'xs', 'sm', 'md', 'lg', 'xl'
        PaperProps={{
          style: {
            minHeight: '400px', // Set a minimum height for the dialog
          },
        }}
      >
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="Country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            error={!!error.country}
            helperText={error.country}
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.country_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="State"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            disabled={!selectedCountry}
            error={!!error.state}
            helperText={error.state}
          >
            {states.map((state) => (
              <MenuItem key={state._id} value={state._id}>
                {state.state_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="District"
            value={selectedDistrict}
            onChange={(e) => setSelectedDistrict(e.target.value)}
            disabled={!selectedState}
            error={!!error.district}
            helperText={error.district}
          >
            {districts.map((district) => (
              <MenuItem key={district._id} value={district._id}>
                {district.district_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Town Name"
            value={townName}
            onChange={(e) => setTownName(e.target.value)}
            error={!!error.townName}
            helperText={error.townName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddTown} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTown;
