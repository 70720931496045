import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Container,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const CourseViewPage = () => {
  const { id } = useParams(); // Get the course ID from the URL
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourse(response.data.data);
      } catch (error) {
        console.error("Error fetching course:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, [id]);

  const handleEdit = () => {
    navigate(`/courses/edit/${id}`); // Navigate to edit page
  };

  return (
    <Container maxWidth="md" >
      <Box padding={2} >
        <Box p={2} sx={{backgroundColor:"#f1f1f1"}}>
          <Typography variant="h4" gutterBottom>
            Course Details
          </Typography>
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : course ? (
          <Box>
            <Typography variant="h6">Course Name: {course.courses_name}</Typography>
            <Typography variant="body1">Details: {course.course_details}</Typography>
            <Typography variant="body1">Category: {course.course_category_id}</Typography>
            <Typography variant="body1">Subcategory: {course.course_subcategory_id}</Typography>
            <Typography variant="body1">Department: {course.department_id}</Typography>
            <Typography variant="body1">Partner: {course.partner_id}</Typography>
            <Typography variant="body1">Grade: {course.grade_id}</Typography>
            <Typography variant="body1">Number of Chapters: {course.no_of_chapters}</Typography>
            <Typography variant="body1">Status: {course.status === 1 ? 'Active' : 'Inactive'}</Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <Typography variant="h6">Thumbnail Image</Typography>
                <img
                  src={`${process.env.REACT_APP_BACKEND}/uploads/${course.course_thumbnail}`}
                  alt="Thumbnail"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Course Image</Typography>
                <img
                  src={`${process.env.REACT_APP_BACKEND}/uploads/${course.course_image}`}
                  alt="Course"
                  style={{ maxWidth: '100%', maxHeight: '200px' }}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              sx={{ mt: 2 }}
            >
              Edit Course
            </Button>
          </Box>
        ) : (
          <Typography variant="body1" color="error">
            Course not found.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default CourseViewPage;
