import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getToken } from "../../utils/AuthUtils";

const EditEventPage = () => {
  const { id } = useParams(); // Get the event ID from the URL params
  const [formData, setFormData] = useState({
    event_title: "",
    event_description: "",
    event_image: null, // Initialize with an empty string for the image
    event_coordinator: "",
    start_time: "",
    end_time: "",
    location: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [formErrors, setFormErrors] = useState({}); // Track form validation errors
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Fetch event details when the component mounts
  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/event/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const eventData = response.data.data;
          setFormData({
            event_title: eventData.event_title,
            event_description: eventData.event_description,
            event_coordinator: eventData.event_coordinator,
            start_time: eventData.start_time,
            end_time: eventData.end_time,
            location: eventData.location,
            event_image: eventData.event_image, // Keep the existing image URL
          });
          setImagePreview(eventData.event_image); // Set the initial image preview
        }
      } catch (err) {
        setError("Error fetching event details");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        event_image: files[0], // Store the file object
      }));
      setImagePreview(URL.createObjectURL(files[0])); // Set the image preview
    } else {
      setFormData({
        ...formData,
        [name]: value, // Update form data for text fields
      });
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check for empty fields and highlight them
    let errors = {};
    if (!formData.event_title) errors.event_title = "Event title is required";
    if (!formData.event_description)
      errors.event_description = "Event description is required";
    if (!formData.event_coordinator)
      errors.event_coordinator = "Event coordinator is required";
    if (!formData.location) errors.location = "Location is required";
    if (!formData.start_time) errors.start_time = "Start time is required";
    if (!formData.end_time) errors.end_time = "End time is required";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Set errors if any field is missing
      setLoading(false);
      return; // Prevent form submission if there are errors
    }

    const data = new FormData();
    for (const key in formData) {
      if (key === "event_image" && formData.event_image instanceof File) {
        data.append(key, formData.event_image);
      } else if (key === "event_image") {
        data.append(key, formData.event_image); // Append the existing image URL
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      const token = getToken();
      if (token) {
        const response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/event/${id}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          navigate("/event_list", {
            state: { snackbarMessage: "Event updated successfully" },
          });
        }
      }
    } catch (err) {
      setError("Error updating event");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({
      ...formData,
      event_image: null, // Remove the image from form data
    });
  };

  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Container maxWidth="100%">
      <Box p={1} sx={{ backgroundColor: "#e0e0e0" }}>
        <Typography variant="h4" gutterBottom>
          Edit Event
        </Typography>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="event_title"
            label="Event Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.event_title} // Populate with existing title
            onChange={handleChange}
            required
            error={!!formErrors.event_title} // Highlight if there's an error
            helperText={formErrors.event_title} // Show error message
          />
          <TextField
            name="event_description"
            label="Event Description"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.event_description}
            onChange={handleChange}
            required
            error={!!formErrors.event_description} // Highlight if there's an error
            helperText={formErrors.event_description} // Show error message
          />
          <input
            name="event_image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "200px", height: "auto", borderRadius: "4px" }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <TextField
            name="event_coordinator"
            label="Event Coordinator"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.event_coordinator}
            onChange={handleChange}
            required
            error={!!formErrors.event_coordinator} // Highlight if there's an error
            helperText={formErrors.event_coordinator} // Show error message
          />
          <TextField
            name="start_time"
            label="Start Time"
            type="datetime-local"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.start_time}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!formErrors.start_time} // Highlight if there's an error
            helperText={formErrors.start_time} // Show error message
          />
          <TextField
            name="end_time"
            label="End Time"
            type="datetime-local"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.end_time}
            onChange={handleChange}
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!formErrors.end_time} // Highlight if there's an error
            helperText={formErrors.end_time} // Show error message
          />
          <TextField
            name="location"
            label="Location"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.location}
            onChange={handleChange}
            required
            error={!!formErrors.location} // Highlight if there's an error
            helperText={formErrors.location} // Show error message
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ me: 4, mt: 2, backgroundColor: "#e0e0e0" }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update Event"}
          </Button>
          {error && <Typography color="error">{error}</Typography>}

          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ ml: 5, mt: 2, backgroundColor: "#e0e0e0" }}
            onClick={handleCancel} // Cancel button to go back
          >
            Cancel
          </Button>
        </Box>
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditEventPage;
