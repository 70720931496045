import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Container,
  Typography,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AddProjectSub from "./AddProjectSub";
import EditProjectSub from "./EditProjectSub";

function ProjectSubList() {
  const [categories, setCategories] = useState([]);
  const [projectCategories, setProjectCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/category_sub`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
          },
        }
      );
      setCategories(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  const fetchProjectCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/category/nopagination`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setProjectCategories(response.data.data);
    } catch (err) {
      setError("Error fetching project categories");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProjectCategories();
  }, [page, rowsPerPage, searchTerm, selectedCategoryId]); // Update dependencies

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryId(null);
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/project/category_sub/delete/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setSnackbarMessage("Sub-Category deleted successfully");
      setOpenSnackbar(true);
      fetchCategories(); // Refresh the list after deleting
    } catch (err) {
      setError("Failed to delete category");
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/project/category_sub/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token_key"),
          },
        }
      );
      setSelectedCategory(response.data.data);
      setSelectedCategoryId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError("Error fetching category details");
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddPartner = () => {
    setSnackbarMessage("Sub-Category added successfully");
    setOpenSnackbar(true);
    handleCloseAddModal();
    fetchCategories(); // Refresh the list after adding
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateCourseStatus(id, newStatus); // Update status in the backend
  };

  const updateCourseStatus = async (id, status) => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND}/api/project/category_sub/${id}/status_project`,
        {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token_key"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      setSnackbarMessage("Sub-Category status updated successfully");
      setOpenSnackbar(true);
      fetchCategories(); // Refresh the list after status change
    } catch (err) {
      setError("Failed to update Course status");
      setOpenSnackbar(true);
    }
  };

  // Create a map from category IDs to category names
  const categoryMap = projectCategories.reduce((map, category) => {
    map[category._id] = category.project_category_name;
    return map;
  }, {});

  const filteredCategories = categories.filter(
    (category) =>
      category.project_sub_category_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) &&
      (selectedCategoryId
        ? category.project_category_id === selectedCategoryId
        : true)
  );

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Project Subcategory</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <TextField
          select
          label="Filter by Category"
          variant="outlined"
          size="small"
          value={selectedCategoryId || ""}
          onChange={(e) => {
            setSelectedCategoryId(e.target.value);
            setPage(0); // Reset to first page when category changes
          }}
          sx={{ width: "300px", marginRight: "10px" }}
        >
          <MenuItem value="">All</MenuItem>
          {projectCategories
            .sort((a, b) =>
              a.project_category_name.localeCompare(
                b.project_category_name,
                "en",
                {
                  sensitivity: "base",
                }
              )
            ) // Sort alphabetically A to Z
            .map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.project_category_name}
              </MenuItem>
            ))}
        </TextField>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Subcategory </TableCell>
              <TableCell>Category </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{category.project_sub_category_name}</TableCell>
                  <TableCell>
                    {categoryMap[category.project_category_id] ||
                      "Unknown Category"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center !important" }}>
                    <Switch
                      checked={category.status}
                      onChange={() =>
                        handleStatusToggle(category._id, category.status)
                      }
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      flexDirection="row"
                        justifyContent="center"
                      alignItems="center" // Optional, aligns buttons horizontally in the center
                    >
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(category._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(category._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                No Subcategory found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddProjectSub
        open={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddPartner}
      />
      {selectedCategoryId && (
        <EditProjectSub
          open={openEditModal}
          onClose={handleCloseEditModal}
          categoryId={selectedCategoryId}
          category={selectedCategory}
          onUpdate={() => {
            setSnackbarMessage("Sub-Category Updated successfully");
            setOpenSnackbar(true);

            handleCloseEditModal();
            fetchCategories(); // Refresh the list after updating
          }}
        />
      )}
      {selectedCategoryId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this category?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteCategory(selectedCategoryId)}
              color="primary"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={error ? "error" : "success"}
        >
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ProjectSubList;
