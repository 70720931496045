import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  TextField,
  Avatar,
  Alert,
} from "@mui/material";

const EditProfile = () => {
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("info"); // 'success' or 'error'

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(file);
      setProfilePicPreview(URL.createObjectURL(file)); // Preview the image
    }
  };

  const handleSave = () => {
    if (!name || !address) {
      setMessageType("error");
      setMessage("Name and Address are required!");
      return;
    }

    // Simulate saving the data (API call can go here)
    setTimeout(() => {
      setMessageType("success");
      setMessage("Profile updated successfully!");
    }, 1000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // minHeight: "100vh",
        padding: "20px",
        width: "100%",
      }}>
      <Card sx={{ width: "600px", boxShadow: 3, borderRadius: "8px" }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Edit Profile
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
              marginTop: 2,
            }}>
            {/* Profile Picture */}
            <Avatar
              src={profilePicPreview}
              alt="Profile Picture"
              sx={{ width: 100, height: 100, marginBottom: 2 }}
            />
            <Button variant="contained" component="label">
              Upload Picture
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleProfilePicChange}
              />
            </Button>

            {/* Name Input */}
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />

            {/* Address Input */}
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              multiline
              rows={3}
              fullWidth
            />

            {/* Save Button */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{
                textTransform: "none",
                padding: "10px 20px",
                backgroundColor: "#e0e0e0",
              }}>
              Save Changes
            </Button>

            {/* Feedback Message */}
            {message && (
              <Alert severity={messageType} sx={{ marginTop: 2 }}>
                {message}
              </Alert>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditProfile;
