import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

function AddSchoolSub({ open, onClose, onAdd }) {
  const [categoryName, setCategoryName] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [schoolOptions, setSchoolOptions] = useState([]);

  // Fetch school categories when component mounts
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/school_category`,
          {
            
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
          setCategoryName(""),
          setSelectedSchool("")

        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (Array.isArray(result.data)) {
          // Filter out schools where status is not 1
          const activeSchools = result.data.filter(
            (school) => school.status === 1
          );
          setSchoolOptions(activeSchools);
        } else {
          console.error("Unexpected data format:", result.data);
        }
      } }catch (error) {
        console.error("Failed to fetch school names:", error);
      }
    };

    fetchSchools();
  }, []);

  // Handle category name change
  const handleCategoryChange = (e) => {
    setCategoryName(e.target.value);
  };

  // Handle school selection change
  const handleSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const token = getToken();
      if (token) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              SubCategory_name: categoryName,
              School_category_id: selectedSchool, // Send the selected school category ObjectId
            }),
          }
        );
  
        // Check if response is successful
        if (response.ok) {
          // If the request was successful, notify parent and close the dialog
          onAdd(); // Notify parent to refresh data
          onClose(); // Close the modal
        } else {
          // If the response is not OK, extract the error message and show it
          const errorData = await response.json(); // Parse response body as JSON
          setSnackbarMessage(errorData?.message || "Failed to add Category");
          setOpenSnackbar(true);
        }
      }
    } catch (error) {
      // Handle unexpected errors (e.g., network issues)
      setSnackbarMessage("Error occurred");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };  

  // Handle Snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth >
      <DialogTitle>Add Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedSchool}
              onChange={handleSchoolChange}
              label="  Name"
            >
              {schoolOptions.length > 0 ? (
                schoolOptions.map((school) => (
                  <MenuItem key={school._id} value={school._id}>
                    {school.school_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No schools available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Name"
            value={categoryName}
            onChange={handleCategoryChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          {/* <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            )}
          </Box> */}
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.includes("success") ? "success" : "error"}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ backgroundColor: "#e0e0e0" }}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSchoolSub;
