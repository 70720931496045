import React, { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

function AddSchool({ open, onClose, onAdd }) {
  const [schoolName, setSchoolName] = useState('');

  const handleChange = (event) => {
    setSchoolName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAdd(schoolName); // Pass the schoolName to the onAdd callback
    setSchoolName("");
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth >
      <DialogTitle>Add Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="  Name"
            value={schoolName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddSchool;
