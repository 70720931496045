import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, MenuItem, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddDepartment = () => {
  const [formValues, setFormValues] = useState({
    department_id: '',
    department_name: '',
    contact_no: '',
    email_address: '',
    category_id: '',
    subcategory_id: '',
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category`);
        setCategories(response.data.data || []);
      } catch (err) {
        console.error('Error fetching categories:', err);
      }
    };

    fetchCategories();
  }, []);

  // Fetch subcategories based on the selected category
  useEffect(() => {
    if (formValues.category_id) 
      {
        console.log("formvalues ",formValues.category_id);
        
      const fetchSubcategories = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category_sub`, {
            params: { department_category_id: formValues.category_id }
          });
          setSubcategories(response.data.data || []);
        } catch (err) {
          console.error('Error fetching subcategories:', err);
        }
      };

      fetchSubcategories();
    } else {
      setSubcategories([]);
      setFilteredSubcategories([]);
    }
  }, [formValues.category_id]);

  // Filter subcategories based on the selected category
  useEffect(() => {
    if (formValues.category_id) {
      const filtered = subcategories.filter(subcategory =>
        subcategory.department_category_id === formValues.category_id
      );
      setFilteredSubcategories(filtered);
    }
  }, [subcategories, formValues.category_id]);

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/department`, formValues);
      navigate('/department_list');
    } catch (err) {
      setError('Error adding department. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setError(null);
  };

  return (
    <Box padding={2}>
      <h2>Create</h2>
      <TextField
        margin="dense"
        label="Department ID"
        fullWidth
        value={formValues.department_id}
        onChange={(e) => setFormValues({ ...formValues, department_id: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Department Name"
        fullWidth
        value={formValues.department_name}
        onChange={(e) => setFormValues({ ...formValues, department_name: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Contact No"
        fullWidth
        value={formValues.contact_no}
        onChange={(e) => setFormValues({ ...formValues, contact_no: e.target.value })}
      />
      <TextField
        margin="dense"
        label="Email Address"
        fullWidth
        value={formValues.email_address}
        onChange={(e) => setFormValues({ ...formValues, email_address: e.target.value })}
      />
      <TextField
        select
        label="Category"
        margin="dense"
        fullWidth
        value={formValues.category_id}
        onChange={(e) => setFormValues({ ...formValues, category_id: e.target.value })}
      >
        {categories.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.department_category_name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Subcategory"
        margin="dense"
        fullWidth
        value={formValues.subcategory_id}
        onChange={(e) => setFormValues({ ...formValues, subcategory_id: e.target.value })}
        disabled={!formValues.category_id} // Disable if no category is selected
      >
        {filteredSubcategories.map((subcategory) => (
          <MenuItem key={subcategory._id} value={subcategory._id}>
            {subcategory.department_sub_category_name}
          </MenuItem>
        ))}
      </TextField>
      <Box marginTop={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          onClick={handleFormSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Department'}
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
          Cancel
        </Button>
      </Box>

      {/* Snackbar for Error and Success Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddDepartment;
