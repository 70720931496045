import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  Container,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import { useLocation } from "react-router-dom";

const LabnamesList = () => {
  const [Labnames, setLabnames] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(""); // New state for selected department
  const navigate = useNavigate();
  const location = useLocation(); // Get the location to check for state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // Track totalPages from the backend
  
  const hasNavigatedRef = useRef(false); // Flag to prevent re-triggering

  // Fetch departments from the backend
  const fetchDepartments = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/department`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200 && response.data) {
          setDepartments(response.data.data);
        } else {
          setError("No departments available");
        }
      }
    } catch (err) {
      setError("Failed to fetch departments.");
      console.error(err);
    }
  };

  // Fetch lab names
  const fetchLabnames = async () => {
    setLoading(true);
    setError(""); // Clear previous error messages
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/labnames`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              page: page + 1, // Backend expects 1-based page index
              limit: rowsPerPage,
              search: searchTerm,
              department: selectedDepartment, 
            },
          }
        );
        if (response.status === 200 && response.data) {
          setLabnames(response.data.data);
          setTotalItems(response.data.pagination.totalItems); // Set totalItems from backend
          setTotalPages(response.data.pagination.totalPages); // Set totalPages from backend
        } else {
          setError("No lab names available");
        }
      }
    } catch (err) {
      setError("Failed to fetch lab names.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    // Fetch departments when the component mounts
    fetchDepartments();
    fetchLabnames();
  }, [page, rowsPerPage, searchTerm, selectedDepartment]); // Add selectedDepartment to the dependency array

  const handleAddCategory = () => {
    navigate("/labnames/add");
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleDepartmentChange = (departId) => {
    setSelectedDepartment(departId); // Update selected department
    setPage(0); // Reset to first page when changing department
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateLabnameStatus(id, newStatus); // Update status in the backend
  };

  const updateLabnameStatus = async (id, status) => {
    try {
      const token = getToken();
      if (token) {
        await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/labnames/${id}/status_labnames`,
          { status },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSnackbarMessage("Labname status updated successfully");
        setOpenSnackbar(true);
        fetchLabnames(); // Refresh the list after status change
      }
    } catch (err) {
      setError("Failed to update lab name status");
      console.error(err);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  }
  const getDepartmentName = (departmentId) => {
    const department = departments.find((dep) => dep._id === departmentId);
    return department ? department.department_name : "Unknown";
  };

  const handleEditCategory = (id) => {
    navigate(`/labnames/edit/${id}`);
  };

  const handleDeleteClick = (id) => {
    setCategoryIdToDelete(id);
    setOpen(true);
  };

  const handleDeleteCategory = async () => {
    if (categoryIdToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.put(
            `${process.env.REACT_APP_BACKEND}/api/labnames/delete/${categoryIdToDelete}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSnackbarMessage("Labname deleted successfully");
          setOpenSnackbar(true);
          fetchLabnames(); // Refresh the list after deletion
        }
      } catch (err) {
        setError("Error deleting lab name");
        console.error(err);
      } finally {
        setOpen(false); // Close the dialog
        setCategoryIdToDelete(null); // Clear the ID
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCategoryIdToDelete(null); // Clear the ID
  };

  const filteredCategories = Labnames.filter(
    (labname) =>
      (labname.lab_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getDepartmentName(labname.department)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      (selectedDepartment
        ? labname.department._id === selectedDepartment
        : true) // Filter by department if selected
  );

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="100%">
      <Box
        p={1}
        sx={{
          backgroundColor: "#e0e0e0cf",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Lab Names</Typography>
        <TextField
          // label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{
            width: "300px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#00000030",
              },
              "&:hover fieldset": {
                borderColor: "#00000070",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#00000040",
              },
            },
            "& .MuiInputLabel-root": {
              display: "flex",
              alignItems: "center",
              transition: "all 0.3s ease",
            },
            "& .MuiInputLabel-shrink": {
              display: "flex",
              alignItems: "center",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={2}
        sx={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <Autocomplete
                value={
                  departments.find((depart)=> depart._id === selectedDepartment)
                   || null 
                  }
                onChange={(event, newValue) => handleDepartmentChange( newValue ? newValue._id : "")}
                options={departments}
                getOptionLabel={(option) => option.department_name}
                isOptionEqualToValue={(option, value) => option._id === value}
                renderInput={(params) => (
                  <TextField {...params} label="Department" size="small" />
                )}
                noOptionsText="No departments available"
                // disableClearable
              />
            </FormControl>
          </Grid>
          {/* Create Button */}
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            justifyContent="flex-end"
            sx={{ order: 2, marginLeft: "auto" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddCategory}
              sx={{ mx: 2, backgroundColor: "#e0e0e0" }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "20px" }}> No.</TableCell>
              <TableCell>Lab Name</TableCell>
              <TableCell>Department Category</TableCell>
              <TableCell>Department Subcategory</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCategories.length > 0 ? (
              filteredCategories.map((labname, index) => {
                return (
                  <TableRow key={labname._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{labname.lab_name}</TableCell>
                    <TableCell>
                      {labname.department_category?.department_category_name
                        ? labname.department_category?.department_category_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {labname.department_subcategory
                        ?.department_sub_category_name
                        ? labname.department_subcategory
                            ?.department_sub_category_name
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {labname.department?.department_name
                        ? labname.department?.department_name
                        : "N/A"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center !important" }}>
                      <Switch
                        checked={labname.status}
                        onChange={() =>
                          handleStatusToggle(labname._id, labname.status)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditCategory(labname._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteClick(labname._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No lab names found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 10 && (
       <TablePagination
       rowsPerPageOptions={[10, 25, 50, 100]}
       component="div"
       count={totalItems} // Set total item count from the backend
       rowsPerPage={rowsPerPage}
       page={page}
       onPageChange={handleChangePage}
       onRowsPerPageChange={handleChangeRowsPerPage}
       labelRowsPerPage="Labs per page"
     />     
      )}

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this lab name? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteCategory}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LabnamesList;
