import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [schools, setSchools] = useState({});
  const [courses, setCourses] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const navigate = useNavigate();
  const token = getToken();

  // Fetch assigned courses
  useEffect(() => {
    const fetchAssignedCourses = async () => {
      setLoading(true);
      try {
        const token = getToken();
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAssignedCourses(data.data);
      }} catch (error) {
        setError("Failed to fetch assigned courses.");
        console.error("Error fetching assigned courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignedCourses();
  }, []);

  // Fetch schools and courses based on IDs
  useEffect(() => {
    const fetchSchoolsAndCourses = async () => {
      if (assignedCourses.length === 0) return;

      const schoolIds = [...new Set(assignedCourses.map(assignment => assignment.school_id))];
      const courseIds = [...new Set(assignedCourses.map(assignment => assignment.course_id))];

      try {
        const [schoolsResponse, coursesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/school`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`,{
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        const schoolsData = schoolsResponse.data.data.reduce((acc, school) => {
          acc[school._id] = school.school_name;
          return acc;
        }, {});

        const coursesData = coursesResponse.data.data.reduce((acc, course) => {
          acc[course._id] = course.courses_name;
          return acc;
        }, {});

        setSchools(schoolsData);
        setCourses(coursesData);
      } catch (error) {
        setError("Failed to fetch schools and courses.");
        console.error("Error fetching schools and courses:", error);
      }
    };

    fetchSchoolsAndCourses();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = () => {
    navigate('/assign_courses');
  };

  const handleEditCourse = (id) => {
    navigate(`/assign_courses/edit/${id}`);
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        
          const token = getToken();
        if (token) {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assign_course/${courseToDelete}`,{
            headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        closeDeleteDialog();
        // Refresh the list after deletion
        fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list
      } }catch (err) {
        setError("Failed to delete the course.");
        console.error('Error deleting course:', err);
      }
    }
  };

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      
      const token = getToken();
      if (token) {
      const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setAssignedCourses(data.data);
    }} catch (error) {
      setError("Failed to fetch assigned courses.");
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="100%">
      <Typography variant="h4" >
        Assigned Courses List
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
              <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            </Snackbar>
          )}

          <Box display="flex" flexDirection="column" padding={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                sx={{ width: '300px' }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddCourse}
              >
                Assign Course
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width:"20px"}}> No.</TableCell>
                    <TableCell>School Name</TableCell>
                    <TableCell>Assigned Course Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignedCourses
                    .filter(assignment => {
                      const courseName = courses[assignment.course_id] || '';
                      return courseName.toLowerCase().includes(searchTerm.toLowerCase());
                    })
                    .map((assignment, index) => (
                      <TableRow key={assignment._id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{schools[assignment.school_id] || "Loading..."}</TableCell>
                        <TableCell>{courses[assignment.course_id] || "Loading..."}</TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleEditCourse(assignment._id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => openDeleteDialog(assignment._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this course?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button color="error" onClick={handleDeleteCourse}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default ListingPage;
