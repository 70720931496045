import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { getToken } from "../../../utils/AuthUtils";

function AddCourseSub({ open, onClose, onAdd }) {
  const [courseName, setCourseName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/api/courses_category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.ok) {
            const activeCategories = data.data.filter(
              (category) => category.status === 1
            );
            setCategories(activeCategories);
          } else {
            setSnackbarMessage(data.message || "Failed to fetch categories");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
          }
        }
      } catch (error) {
        setSnackbarMessage("Error fetching categories");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCourseNameChange = (e) => {
    setCourseName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };

  const handleCourseSubmit = async (e) => {
    e.preventDefault();
    if (!courseName || !categoryId) {
      setSnackbarMessage("Please fill in all fields");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      const token = getToken();
      if (token) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/courses_category_sub`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              courses_sub_category_name: courseName,
              course_category_id: categoryId,
            }),
          }
        );
        onClose();
        setCourseName("");
        setCategoryId("");
        if (response.ok) {
          // setSnackbarMessage("Course added successfully");
          // setSnackbarSeverity("success");
          onAdd(); // Notify parent to refresh data
          // Close the modal
        } else {
          const result = await response.json();
          setSnackbarMessage(result.message || "Failed to add course");
          setSnackbarSeverity("error");
        }
      }
    } catch (error) {
      setSnackbarMessage("Error occurred while adding course");
      setSnackbarSeverity("error");
    } finally {
      // setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}  maxWidth="sm" fullWidth>
      <DialogTitle>Add Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleCourseSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Category</InputLabel>
            <Select
              value={categoryId}
              onChange={handleCategoryChange}
              label="Category"
              disabled={loading}
            >
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.courses_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Name"
            value={courseName}
            onChange={handleCourseNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            disabled={loading}
          />
        </form>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={handleCourseSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCourseSub;
