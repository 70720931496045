import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";
import { Flex } from "antd";

const AssignCourse = () => {
  const [schoolCategoryId, setSchoolCategoryId] = useState("");
  const [schoolSubCategoryId, setSchoolSubCategoryId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [alreadyAssignedCourses, setAlreadyAssignedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [filteredSchoolSubCategories, setFilteredSchoolSubCategories] =
    useState([]);
  const [schools, setSchools] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [courses, setCourses] = useState([]);

  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchoolCategories = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/school_category`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setSchoolCategories(
            data.data.filter((category) => category.status === 1)
          );
        }
      } catch (error) {
        setError("Failed to fetch school categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolCategories();
  }, []);

  useEffect(() => {
    const fetchDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        const token = getToken();
        if (token) {
          if (schoolCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/sub_school_category`,
              {
                params: { category_id: schoolCategoryId },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setSchoolSubCategories(
              data.data.filter((subCategory) => subCategory.status === 1)
            );
            setFilteredSchoolSubCategories(
              data.data.filter((subCategory) => subCategory.status === 1)
            ); // Set initial filtered
          } else {
            setSchoolSubCategories([]);
            setFilteredSchoolSubCategories([]);
          }

          if (schoolSubCategoryId) {
            const { data } = await axios.get(
              `${process.env.REACT_APP_BACKEND}/api/school/schoolget/current-user/${schoolCategoryId}/${schoolSubCategoryId}`,
              {
                // params: { subcategory_id: schoolSubCategoryId },
                headers: { Authorization: `Bearer ${token}` },
              }
            );
            setSchools(data.data.filter((school) => school.status === 1));
            setFilteredSchools(
              data.data.filter((school) => school.status === 1)
            ); // Set initial filtered
          } else {
            setSchools([]);
            setFilteredSchools([]);
          }
        }
      } catch (error) {
        setError("Failed to fetch dependent data.");
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnSelections();
  }, [schoolCategoryId, schoolSubCategoryId]);

  // Update filtered subcategories when schoolCategoryId changes
  useEffect(() => {
    const filtered = schoolSubCategories.filter(
      (subcategory) => subcategory.School_category_id === schoolCategoryId
    );
    setFilteredSchoolSubCategories(filtered);
  }, [schoolCategoryId, schoolSubCategories]);

  // Update filtered schools when schoolSubCategoryId changes
  // useEffect(() => {
  //   const filtered = schools.filter(
  //     (school) => school.school_sub_category === schoolSubCategoryId
  //   );
  //   setFilteredSchools(filtered);
  // }, [schoolSubCategoryId, schools]);

  useEffect(() => {
    const fetchAlreadyAssignedCourses = async () => {
      if (schoolId) {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/assign_course/school/assigned_courses_school/${schoolId}`,
            {
              params: {
                pagnation: 0,
              },
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setAlreadyAssignedCourses(data.data);
          setSelectedCourses(data.data.map((course) => course.course_id));
        } catch (error) {
          setError("Failed to fetch assigned courses.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAlreadyAssignedCourses();
  }, [schoolId]);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/getassigned_courses_by_partner`,
          {
            params: {
              pagnation: 0,
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCourses(data.data.filter((course) => course.status === 1));
      } catch (error) {
        setError("Failed to fetch courses.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleCourseChange = (courseId) => {
    setSelectedCourses((prev) => {
      if (prev.includes(courseId)) {
        return prev.filter((id) => id !== courseId);
      } else {
        return [...prev, courseId];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (
      !schoolCategoryId ||
      !schoolSubCategoryId ||
      !schoolId ||
      selectedCourses.length === 0
    ) {
      setError("Please fill out all required fields.");
      return;
    }

    const filteredSelectedCourses = selectedCourses.filter(
      (courseId) =>
        !alreadyAssignedCourses.some(
          (assignedCourse) => assignedCourse.course_id === courseId
        )
    );

    if (filteredSelectedCourses.length === 0) {
      setError("No new courses selected to assign.");
      return;
    }

    const assignData = {
      school_category_id: schoolCategoryId,
      school_sub_category_id: schoolSubCategoryId,
      school_id: schoolId,
      course_ids: filteredSelectedCourses,
    };

    setLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/assign_course/school`,
        assignData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate("/partner/assign_courses_list", {
        state: { snackbarMessage: "course assigned successfully" },
      });
      // Reset fields after successful submission
      setSchoolCategoryId("");
      setSchoolSubCategoryId("");
      setSchoolId("");
      setSelectedCourses([]);
    } catch (error) {
      setError("Failed to assign course.");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };
  const handleCancel = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Typography variant="h4" gutterBottom>
          Assign Course To School
        </Typography>
      </Grid>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} gap={2}>
                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select School Category"
                    value={schoolCategoryId}
                    onChange={(e) => {
                      setSchoolCategoryId(e.target.value);
                      setSchoolSubCategoryId(""); // Reset dependent state
                      setSchools([]); // Reset schools
                    }}
                  >
                    {schoolCategories.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.school_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select School Subcategory"
                    value={schoolSubCategoryId}
                    onChange={(e) => {
                      setSchoolSubCategoryId(e.target.value);
                      setSchools([]); // Reset schools
                    }}
                  >
                    {filteredSchoolSubCategories.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.SubCategory_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    select
                    label="Select School"
                    value={schoolId}
                    onChange={(e) => setSchoolId(e.target.value)}
                  >
                    {filteredSchools.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.school_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">Already Assigned Courses</Typography>
                  {alreadyAssignedCourses.length > 0 ? (
                    alreadyAssignedCourses.map((item) => (
                      <div key={item._id}>
                        <span>Course: {item.course_id.courses_name}</span>
                      </div>
                    ))
                  ) : (
                    <p>No courses assigned.</p>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mr: 5, mt: 2, backgroundColor: "#e0e0e0" }}
                  >
                    Assign Courses
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
                    onClick={handleCancel} // Cancel button to go back
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                alignItems="center" 
                sx={{
                  height: "500px", // Set the specific height you want for the grid
                  overflowY: "auto", // Enable scrolling vertically if content exceeds the container height
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide the scrollbar
                  },
                }}
              >
                <Typography variant="h6">Available Courses</Typography>
                <FormGroup>
                  {courses
                    .filter(
                      (course) =>
                        !alreadyAssignedCourses.some(
                          (assignedCourse) =>
                            assignedCourse.course_id._id ===
                            course.course_id._id
                        )
                    )
                    .map((course) => (
                      <FormControlLabel
                        key={course._id}
                        control={
                          <Checkbox
                            // checked={selectedCourses.includes(course.course_id._id)}
                            onChange={() =>
                              handleCourseChange(course.course_id._id)
                            }
                          />
                        }
                        label={course.course_id.courses_name}
                      />
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Snackbar
        open={success !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>

      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignCourse;
