import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Container,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditSchoolMain = () => {
  const { id } = useParams(); // Get ID from URL parameters
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    School_id: "",
    school_category: "",
    school_code: "",
    school_sub_category: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    place: "",
    school_official_id: "",
    school_name: "",
    school_address: "",
    school_pin_code: "",
    school_website: "",
    school_email: "",
    principal_name: "",
    principal_contact_no: "",
    principal_email: "",
    principal_photo: "",
    admin_name: "",
    admin_contact_no: "",
    admin_email: "",
    total_number_of_students: "",
    school_logo: "",
    social_media_linkedin: "",
    social_media_instagram: "",
    social_media_facebook: "",
    status: 1,
  });
  const [error, setError] = useState("");
  const [errorVal, setErrorVal] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [principalPhoto, setPrincipalPhoto] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [principalPhotoPreview, setPrincipalPhotoPreview] = useState("");
  const [schoolLogoPreview, setSchoolLogoPreview] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]); // For school categories
  const [schoolSubCategories, setSchoolSubCategories] = useState([]); // For school subcategories
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // success or error

  useEffect(() => {
    fetchCountries();
    fetchSchoolCategories();
    // Fetch school categories on mount
    if (id) fetchSchoolData(id);
  }, [id]);

  useEffect(() => {
    if (formData.school_sub_category) {
      fetchSchoolSubCategories(formData.school_sub_category);
    }
  }, [formData.school_sub_category]);

  useEffect(() => {
    if (formData.countryId) fetchStates(formData.countryId);
    else {
      setStates([]);
      setFormData((prev) => ({
        ...prev,
        stateId: "",
        districtId: "",
        townId: "",
      }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) fetchDistricts(formData.stateId);
    else {
      setDistricts([]);
      setFormData((prev) => ({ ...prev, districtId: "", townId: "" }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) fetchTowns(formData.districtId);
    else {
      setTowns([]);
      setFormData((prev) => ({ ...prev, townId: "" }));
    }
  }, [formData.districtId]);

  useEffect(() => {
    if (formData.principal_photo) {
      setPrincipalPhotoPreview(
        `${formData.principal_photo}`
      );
    }
    if (formData.school_logo) {
      setSchoolLogoPreview(
        `${formData.school_logo}`
      );
    }
  }, [formData.principal_photo, formData.school_logo]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country?limit=300`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch countries:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/state/by-country?limit=300`,
          {
            params: { countryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStates(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`,
          {
            params: { stateId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDistricts(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/town/by-district?limit=300`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { districtId },
          }
        );
        setTowns(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolData = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch school data:",
        err.response?.data?.message || err.message
      );
    }
  };
  useEffect(() => {
    fetchSchoolData(id);
  }, [formData.principal_photo, formData.school_logo]);

  useEffect(() => {
    if (formData.school_category) {
      const filtered = schoolSubCategories.filter(
        (subcategory) =>
          subcategory.School_category_id === formData.school_category
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [formData.school_category, schoolSubCategories]);

  const fetchSchoolCategories = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/school_category?limit=3000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSchoolCategories(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch school categories:",
        err.response?.data?.message || err.message
      );
    }
  };

  const fetchSchoolSubCategories = async (categoryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/sub_school_category?limit=300`,

          {
            params: { categoryId },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("subcategory:", response.data.data);
        setSchoolSubCategories(response.data.data);
      }
    } catch (err) {
      console.error(
        "Failed to fetch school subcategories:",
        err.response?.data?.message || err.message
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };

      // Fetch Subcategory when school category changes
      if (name === "school_category") {
        fetchSchoolSubCategories(value);
      }

      return updatedFormData;
    });
  };
  const handleCancel = () => {
     // Reset form state
  setFormData({
    School_id: "",
    school_category: "",
    school_code: "",
    school_sub_category: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    place: "",
    school_official_id: "",
    school_name: "",
    school_address: "",
    school_pin_code: "",
    school_website: "",
    school_email: "",
    principal_name: "",
    principal_contact_no: "",
    principal_email: "",
    principal_photo: "",
    admin_name: "",
    admin_contact_no: "",
    admin_email: "",
    total_number_of_students: "",
    school_logo: "",
    social_media_linkedin: "",
    social_media_instagram: "",
    social_media_facebook: "",
    status: 1,
  });
  // Reset previews
  setPrincipalPhotoPreview('');
  setSchoolLogoPreview('');
    navigate("/partner/school_list"); // Navigate to the desired route on cancel
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
       // Example validation: only allow image files
    if (!file.type.startsWith("image/")) {
      alert("Please select a valid image file.");
      return;
    }
      if (name === "principal_photo") {
        setPrincipalPhoto(file);
        setPrincipalPhotoPreview(URL.createObjectURL(file)); // Update preview immediately
      } else if (name === "school_logo") {
        setSchoolLogo(file);
        setSchoolLogoPreview(URL.createObjectURL(file)); // Update preview immediately
      }
    }
  };

  // When you're done with the object URL (e.g., when the component unmounts)
useEffect(() => {
  return () => {
    if (principalPhotoPreview) {
      URL.revokeObjectURL(principalPhotoPreview);
    }
    if (schoolLogoPreview) {
      URL.revokeObjectURL(schoolLogoPreview);
    }
  };
}, [principalPhotoPreview, schoolLogoPreview]);

  const handleSubmit = async () => {
    try {
      const token = getToken();
      if (!token) {
        alert("Please Login");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      
      if (principalPhoto) {
        formDataToSend.append("principal_photo", principalPhoto);
      }
      
      if (schoolLogo) {
        formDataToSend.append("school_logo", schoolLogo);
      }

      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/school/${id}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/partner/school_list", {
        state: { snackbarMessage: "School updated successfully!" },
      }); // Redirect to the school list page after updating
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const errors = err.response.data.errors;

        // Handle errors field by field and set them in the error state
        const formErrors = {};
        errors.forEach((error) => {
          formErrors[error.field] = error.message;
        });

        // Update the error state with the field-specific error messages
        setErrorVal(formErrors);

        // Trigger error snackbar with a generic error message
        setSnackbarMessage(
          "Failed to add school. Please check your inputs and try again."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } else {
        // If no specific validation errors, show a generic error message
        setSnackbarMessage(
          "An unexpected error occurred. Please try again later."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
      setError("Failed to update school");
    }
  };

  return (
    <Container maxWidth="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        padding={1}
        sx={{ backgroundColor: "#e0e0e0" }}
      >
        <Typography variant="h4">Edit School</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={2}>
          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address Information
            </Typography>
            <Grid container spacing={2}>
              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="countryId"
                    value={formData.countryId}
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* State */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="stateId"
                    value={formData.stateId}
                    onChange={handleChange}
                    disabled={!formData.countryId}
                  >
                    {states.map((state) => (
                      <MenuItem key={state._id} value={state._id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.stateId && (
                    <span className="error-message">{errorVal.stateId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* District */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>District</InputLabel>
                  <Select
                    name="districtId"
                    value={formData.districtId}
                    onChange={handleChange}
                    disabled={!formData.stateId}
                  >
                    {districts.map((district) => (
                      <MenuItem key={district._id} value={district._id}>
                        {district.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.districtId && (
                    <span className="error-message">{errorVal.districtId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* Town */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Town</InputLabel>
                  <Select
                    name="townId"
                    value={formData.townId}
                    onChange={handleChange}
                    disabled={!formData.districtId}
                  >
                    {towns.map((town) => (
                      <MenuItem key={town._id} value={town._id}>
                        {town.Town_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.townId && (
                    <span className="error-message">{errorVal.townId}</span>
                  )}
                </FormControl>
              </Grid>
              {/* Place */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Place"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                  error={!!errorVal.place}
                  helperText={errorVal.place}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
            <Grid container spacing={2}>
              {/* School Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Name"
                  name="school_name"
                  value={formData.school_name}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    if (/^[A-Za-z]*$/.test(value) && value.length <= 5) {
                      handleChange(e);
                    }
                  }}
                  error={!!errorVal.school_code}
                  helperText={errorVal.school_code || (formData.school_code.length < 3 || formData.school_code.length > 5 ? "Length must be between 3 and 5 characters." : "")}
                />
              </Grid>
              {/* School Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Code"
                  name="school_code"
                  value={formData.school_code}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    if (/^[A-Za-z]*$/.test(value) && value.length <= 5) {
                      handleChange(e);
                    }
                  }}
                  error={!!errorVal.school_code}
                  helperText={errorVal.school_code || (formData.school_code.length < 3 || formData.school_code.length > 5 ? "Length must be between 3 and 5 characters." : "")}
                />
              </Grid>
              {/* School Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>School Category</InputLabel>
                  <Select
                    name="school_category"
                    value={formData.school_category}
                    onChange={handleChange}
                  >
                    {schoolCategories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.school_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.school_category && (
                    <span className="error-message">{errorVal.school_category}</span>
                  )}
                </FormControl>
              </Grid>
              {/* School Subcategory */}
              <Grid item xs={12} sm={2} md={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  disabled={!formData.school_category}
                >
                  <InputLabel>School Subcategory</InputLabel>
                  <Select
                    name="school_sub_category"
                    value={formData.school_sub_category}
                    onChange={handleChange}
                  >
                    {filteredSubCategories.map((subCat) => (
                      <MenuItem key={subCat._id} value={subCat._id}>
                        {subCat.SubCategory_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errorVal.school_sub_category && (
                    <span className="error-message">{errorVal.school_sub_category}</span>
                  )}
                </FormControl>
              </Grid>
              {/* School Address */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="School Address"
                  name="school_address"
                  value={formData.school_address}
                  onChange={handleChange}
                  error={!!errorVal.school_address}
                  helperText={errorVal.school_address}
                />
              </Grid>
              {/* School Pin Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Pin Code"
                  name="school_pin_code"
                  value={formData.school_pin_code}
                  onChange={handleChange}
                  error={!!errorVal.school_pin_code}
                  helperText={errorVal.school_pin_code}
                />
              </Grid>
              {/* School Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Website"
                  name="school_website"
                  value={formData.school_website}
                  onChange={handleChange}
                  error={!!errorVal.school_website}
                  helperText={errorVal.school_website}
                />
              </Grid>
              {/* School Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Email"
                  name="school_email"
                  value={formData.school_email}
                  onChange={handleChange}
                  error={!!errorVal.school_email}
                  helperText={errorVal.school_email}
                />
              </Grid>
              {/* Principal Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Name"
                  name="principal_name"
                  value={formData.principal_name}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    // Allow only alphabetic characters (A-Z, a-z)
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  error={!!errorVal.principal_name}
                  helperText={errorVal.principal_name}
                />
              </Grid>
              {/* Principal Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Contact No"
                  name="principal_contact_no"
                  value={formData.principal_contact_no}
                  onChange={handleChange}
                  error={!!errorVal.principal_contact_no}
                  helperText={errorVal.principal_contact_no}
                />
              </Grid>
              {/* Principal Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Email"
                  name="principal_email"
                  value={formData.principal_email}
                  onChange={handleChange}
                  error={!!errorVal.principal_email}
                  helperText={errorVal.principal_email}
                />
              </Grid>
              {/* Principal Photo */}
              {/* <Grid item xs={12} md={6}>
                <input
                  accept="image/*"
                  type="file"
                  name="principal_photo"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="principal-photo-upload"
                />
                <label htmlFor="principal-photo-upload">
                  <Button variant="contained" component="span">
                    Upload Principal Photo
                  </Button>
                </label>
                {principalPhotoPreview && (
                  <Box mt={2}>
                    <Typography variant="caption">Preview:</Typography>
                    <img src={principalPhotoPreview} alt="Principal Preview" style={{ width: '100px', height: 'auto', marginTop: '5px' }} />
                  </Box>
                )}
              </Grid> */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  Principal Photo
                </Typography>
                <Box>
                  {/* Display Principal Photo Preview */}
                  {principalPhotoPreview ? (
                    <img
                      src={principalPhotoPreview}
                      alt="Principal Photo Preview"
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : formData.principal_photo ? (
                    <img
                      src={formData.principal_photo}
                      alt="Current Principal Photo"
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    "No Photo"
                  )}
                  <Button variant="contained" component="label">
                    {principalPhoto
                      ? "Change Principal Photo"
                      : "Upload Principal Photo"}
                    <input
                      type="file"
                      name="principal_photo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                  {errorVal.principal_photo && (
                  <span className="error-message">{errorVal.principal_photo}</span>
                )}
                </Box>
              </Grid>
              {/* Admin Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={(e) => {
                    const value = e.target.value;
  
                    // Allow only alphabetic characters (A-Z, a-z)
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  error={!!errorVal.admin_name}
                  helperText={errorVal.admin_name}
                />
              </Grid>
              {/* Admin Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Contact No"
                  name="admin_contact_no"
                  value={formData.admin_contact_no}
                  onChange={handleChange}
                  error={!!errorVal.admin_contact_no}
                  helperText={errorVal.admin_contact_no}
                />
              </Grid>
              {/* Admin Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleChange}
                  error={!!errorVal.admin_email}
                  helperText={errorVal.admin_email}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  School Logo
                </Typography>
                <Box>
                  {/* Display School Logo Preview */}
                  {schoolLogoPreview ? (
                    <img
                      src={schoolLogoPreview}
                      alt="School Logo Preview"
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : formData.school_logo ? (
                    <img
                      src={`${process.env.REACT_APP_BACKEND}/uploads/${formData.school_logo}`}
                      alt="Current School Logo"
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 10,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    "No Logo"
                  )}
                  <Button variant="contained" component="label">
                    {schoolLogo ? "Change School Logo" : "Upload School Logo"}
                    <input
                      type="file"
                      name="school_logo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                  {errorVal.school_logo && (
                  <span className="error-message">{errorVal.school_logo}</span>
                )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          
        </Grid>

        {/* Social Media Links */}
        <Typography variant="h6" gutterBottom>
          Social Media Information
        </Typography>
        <Grid container spacing={2}>
          {/* LinkedIn */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="LinkedIn"
              name="social_media_linkedin"
              value={formData.social_media_linkedin}
              onChange={handleChange}
            />
          </Grid>
          {/* Instagram */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Instagram"
              name="social_media_instagram"
              value={formData.social_media_instagram}
              onChange={handleChange}
            />
          </Grid>
          {/* Facebook */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Facebook"
              name="social_media_facebook"
              value={formData.social_media_facebook}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            sx={{ mt: 2, mx: 2, backgroundColor: "#e0e0e0" }}
          >
            Update Changes
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel} // You can define this function to handle the cancel action
            sx={{ mt: 2, mx: 2, backgroundColor: "#e0e0e0" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditSchoolMain;
