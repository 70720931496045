import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, Snackbar, Alert, FormHelperText } from '@mui/material';
import { getToken } from '../utils/AuthUtils';

const AddDistrict = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districtName, setDistrictName] = useState('');
  const [districtCode, setDistrictCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [errors, setErrors] = useState({
    districtName: '',
    districtCode: '',
    selectedCountry: '',
    selectedState: '',
  });

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country/nopagination`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCountries(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch countries');
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
          params: { countryId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStates(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch states');
    }
  };

  const validateForm = () => {
    const newErrors = {
      districtName: districtName ? '' : 'District name is required',
      districtCode: districtCode ? '' : 'District code is required',
      selectedCountry: selectedCountry ? '' : 'Country is required',
      selectedState: selectedState ? '' : 'State is required',
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error); // If no errors, return true
  };

  const handleAddDistrict = async () => {
    if (!validateForm()) {
      return; // If form is not valid, do not submit
    }
    try {
      const token = getToken();
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/district`,
          {
            district_name: districtName,
            district_code: districtCode,
            country: selectedCountry,
            state: selectedState,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        onAdd();
        onClose();
        setSnackbarMessage('District added successfully');
        setOpenSnackbar(true);
      }
    } catch (err) {
      console.error('Failed to add District');
      setSnackbarMessage('Failed to add district');
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Determine the severity based on the snackbarMessage
  const severity = snackbarMessage && snackbarMessage.startsWith('Failed') ? 'error' : 'success';

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true} // This makes the dialog take up the full width
        maxWidth="sm"   // You can adjust the maxWidth to 'xs', 'sm', 'md', or 'lg'
        PaperProps={{
          style: {
            minHeight: '400px', // Adjust minimum height
          },
        }}
      >
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="Country"
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            error={Boolean(errors.selectedCountry)}
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.country_name}
              </MenuItem>
            ))}
          </TextField>
          {errors.selectedCountry && <FormHelperText error>{errors.selectedCountry}</FormHelperText>}

          <TextField
            margin="normal"
            required
            fullWidth
            select
            label="State"
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            error={Boolean(errors.selectedState)}
          >
            {states.map((state) => (
              <MenuItem key={state._id} value={state._id}>
                {state.state_name}
              </MenuItem>
            ))}
          </TextField>
          {errors.selectedState && <FormHelperText error>{errors.selectedState}</FormHelperText>}

          <TextField
            margin="normal"
            required
            fullWidth
            label="District Name"
            value={districtName}
            onChange={(e) => setDistrictName(e.target.value)}
            error={Boolean(errors.districtName)}
          />
          {errors.districtName && <FormHelperText error>{errors.districtName}</FormHelperText>}

          <TextField
            margin="normal"
            required
            fullWidth
            label="District Code"
            value={districtCode}
            onChange={(e) => setDistrictCode(e.target.value)}
            error={Boolean(errors.districtCode)}
          />
          {errors.districtCode && <FormHelperText error>{errors.districtCode}</FormHelperText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddDistrict} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={severity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddDistrict;
